.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-10hburv-MuiTypography-root {
  font-family: 'Manrope', sans-serif;
}


@media (max-width: 768px) {
  .MuiListItem-root.MuiListItem-gutters.MuiListItem-divider{
    padding-left: 0;
  }

  .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.MuiListItemButton-root.MuiListItemButton-gutters.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root {
    padding-left: 0;
  }
}