.pdf-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.transpose {
  padding-left: 1rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: start;
}

.display-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
}

.item-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.toggleButton {
  min-width: 48px; 
}

@media (max-width: 768px) {
  .toggleButton {
    min-width: 64px;
    min-height: 64px;
  }
}

.accordion-title {
  color: #141156;
  font-weight: bold;
}

.css-1twr7re-JoyAccordionSummary-button:hover {
  background-color: transparent !important;
}

.css-1twr7re-JoyAccordionSummary-button[aria-selected="true"] {
  background-color: transparent !important;
}

.header-text {
  font-family: 'Manrope', sans-serif;
  font-weight: bold;
}

.header-icon {
  height: 3rem;
  width: 3rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

@media screen and (max-width: 767px) {
  .header-icon {
    height: 2rem;
    width: 2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .header-icon {
    height: 3rem;
    width: 3rem;
  }
}

.header-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header-icon {
  margin: 0 4px;
}

.header-icon.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}