.modal-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cards-list {
    padding-left: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-category {
    position: fixed;
    bottom: 6rem;
    right: 2rem;
    display: flex;
    align-items: center;
  }

.add-hymns {
    display: flex;
    justify-content: end;
    align-items: center;
}

.MuiTypography-root {
    padding-left: 1rem;
    padding-right: 1rem;
  }