.navbar {
  top: 0;
  left: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  padding: 1rem;
  z-index: 1000;
  width: 100%;
  transition: height 0.3s ease;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.logo-img {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-img img {
  width: 7%;
  height: auto;
}

.logo-text {
  font-family: 'Pacifico', cursive;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.logo-hymn {
  text-align: center;
  font-family: 'Manrope', sans-serif;
  white-space: normal;
  word-wrap: break-word;
  max-width: 70%;
  line-height: 1.2;
  font-size: 1.5rem;
  font-weight: bold;
  margin: auto;
}

.page-title {
  font-family: 'Manrope', sans-serif;
  font-weight: bold;
  color: white;
  font-size: 2rem;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.home-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.home-title {
  font-family: 'Manrope', sans-serif;
  display: flex;
  font-size: 2rem;
  font-weight: bold;
  align-items: center;
  justify-content: center;
}

.home-subtitle {
  font-family: 'Manrope', sans-serif;
  display: flex;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  line-height: 1.1; 
}

/* mobile */
@media screen and (max-width: 767px) {
  .logo-img img {
    width: 3rem;
    height: 3rem;
  }
  .logo-text {
    font-size: 1.2rem;
  }
  .page-title {
    font-size: 1.5rem;
  }
}

/* tablet */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .logo-img img {
    width: 5rem;
    height: 5rem;
  }
}
