@import "~react-pdf/dist/esm/Page/AnnotationLayer.css";/* global.css */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap'); */

:root {
  --font-body: 'Manrope', sans-serif;
  --font-heading: 'Manrope', sans-serif;
  --font-size-base: 16px;
  --line-height-base: 1.6;
  --color-text: #333;
  --color-heading: #222;
  --color-link: #007bff;
}

.app {
  font-family: var(--font-body);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: var(--color-text);
  width: 100vw;
  /* height: 100vh; */
  display: flex;
  /* TEMP FIX */
  /* overflow-y: hidden; */
  overflow-x: hidden;
  flex-direction: column;
}

.page {
  width: 100%;
}


.hidden {
  display: none;
}

.row >div #icon {
  flex: 30%;
}

.row >div #title {
  flex: 70%;
}

.loginButtonWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.app-footer {
  position: fixed;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f9f9f9;
  border-top: 1px solid #e7e7e7;
  z-index: 1000;
}
@media screen and (max-width: 767px) {
  .app-footer {
    font-size: small;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.update-notification {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px 30px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.update-notification button {
  margin-left: 20px;
  padding: 8px 16px;
  background-color: #721c24;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1rem;
}