.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 80vh; */
    padding-top: 40vh;
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .spinner {
    border: 0.8rem solid #f3f3f3;
    border-top: 0.8rem solid #3498db;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  