.document-show {
    display: block;
  }
  
.document-hide {
  display: none;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 6rem; */
}

@media (max-width: 768px) {
  .center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 6rem; */
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
  
  .editable-content {
    white-space: nowrap;
  }
}

.document-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page-container {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding-bottom: 4rem;
}

.controls button {
  margin: 0 1rem;
}

.hymn-document {
  padding-top: 1rem;
}

/* HymnBody.css */

/* Loading animation */
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
  color: #555;
}

.loading::after {
  content: '';
  display: block;
  width: 24px;
  height: 24px;
  margin: 10px auto;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #666;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}


