.container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full height of the viewport now applied to container, not to the children. */
    /*overflow-y: auto; /* Enable vertical scrolling */
    /*-webkit-overflow-scrolling: touch;*/
    overflow-y: hidden;
    padding: 0;
}

/* Loading animation */
.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    color: #555;
  }
  
  .loading::after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    margin: 10px auto;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #666;
    animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }