.image-row {
    display: flex;
    justify-content: center;
  }
  
/* .image-item {
  align-items: center;
  width: 15%;
  height: 15%;
} */
  
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .image-item {
    width: 30%;
    height: 30%;
  }
}
    
@media screen and (min-width: 380px) and (max-width: 767px) {
  .image-item {
    width: 40%;
    height: 40%;
  }
}
    
@media screen and (max-width: 379px) {
  .image-item {
    width: 30%;
    height: 30%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1510px) {
  .image-item {
    width: 20%;
    height: 20%;
  }
}

@media screen and (min-width: 1511px) and (max-width: 2048px) {
  .image-item {
    width: 10%;
    height: 10%;
  }
}

@media screen and (min-width: 2049px){
  .image-item {
    width: 10%;
    height: 10%;
  }
}