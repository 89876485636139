.custom-button {
    width: 80%;
    max-width: 300px;
    height: 60px;
    font-size: 1.2rem;
    /* font-weight: bold; */
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #323F50;
  }
  
  .custom-button:hover {
    background-color: #625e60;
    transform: translateY(-2px);
  }
  
  .custom-button:active {
    background-color: #323F50;
    transform: translateY(0);
  }
  
  .App-logo {
    width: 12rem;
    height: auto;
  }
  
  .text-center {
    text-align: center;
  }
  
  .d-flex {
    display: flex;
  }
  
  .flex-column {
    flex-direction: column;
  }
  
  .align-items-center {
    align-items: center;
  }
  
  .pt-4 {
    padding-top: 1.5rem;
  }
  
  .pb-2 {
    padding-bottom: 0.5rem;
  }
  
  .mt-2 {
    margin-top: 0.5rem;
  }
  
  .mb-4 {
    margin-bottom: 1.5rem;
  }
  
  .w-80 {
    width: 80%;
  }
  
  .h-20 {
    height: 20%;
  }

  .result {
    font-family: 'Manrope', sans-serif;
    font-size: 1.2rem;
  }

  .no-results {
    font-family: 'Manrope', sans-serif;
    font-size: 1.2rem;
  }