.navigation {
  padding-bottom: 20px;
  padding-top: 10px;
}

.navigation-icon {
  height: 2.5rem;
  width: 2.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

@media screen and (max-width: 767px) {
  .navigation-icon {
    height: 2rem;
    width: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.navigation-icon.selected {
  filter: invert(35%) sepia(97%) saturate(1868%) hue-rotate(178deg) brightness(91%) contrast(95%);
}


@media screen and (max-width: 560px) {
  .mobile-hide {
    display: none;
  }
}